.box.tables-by-buckets {
  .box-content {
    padding: 8px;
  }
}

.bucket-panel {
  .svg-inline--fa + h4 {
    display: inline-block;
    padding-left: 8px;
  }

  .table {
    &-hover {
      padding-top: 4px;
    }

    .tbody {
      a.tr:hover {
        color: inherit;
      }
    }
  }
}
