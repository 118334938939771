.dashboard-welcome-widget {
  padding: 20px;
  background: linear-gradient(142.72deg, #3e9eff 0%, @secondary-600 100%);

  &,
  h2,
  h3 {
    color: @secondary-100;
  }

  h2 {
    color: @white;
  }

  h2,
  h3 {
    margin: 0 8px 16px;
  }

  .btn.close {
    margin-top: -3px;
    color: @white;
    opacity: 1;
  }
}

.video-widget-wrapper {
  .btn {
    position: relative;
    padding: 0 !important;

    & > img {
      border-radius: @border-radius;
    }

    & > span.circle-icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 32px;
      min-width: 32px;
      height: 32px;

      svg {
        bottom: 0px;
        left: 1px;
        width: 12px;
        height: 14px;
      }
    }

    &:hover span.circle-icon,
    &:focus span.circle-icon {
      background-color: @secondary-600;
    }
  }
}

.modal.video-widget-modal iframe {
  width: 100%;
}
