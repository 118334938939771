.separator {
  font-size: 11px;
  font-weight: 600;
  color: @neutral-400;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  margin: 20px 0;

  &::after,
  &::before {
    content: '';
    flex: 1;
    border-bottom: @border;
  }

  &:not(:empty)::before {
    margin-right: 5px;
  }

  &:not(:empty)::after {
    margin-left: 5px;
  }
}
