@import (inline) '@xyflow/react/dist/style.css';

.react-flow__node:not(.selected):has([data-bottom-content='true']) {
  z-index: 10 !important;
}

.react-flow__edge {
  pointer-events: none;
  stroke: #b1b1b7 !important;
}

.react-flow__node:has([data-zoom-on-hover='true']) {
  &:hover {
    z-index: 1 !important;
  }
}

.node-custom {
  &:hover {
    transform: scale(var(--zoom)) !important;
  }
}
