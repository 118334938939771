@import url('@keboola/flow-builder/styles');

@flow-box-shadow: 0px 2px 4px rgba(191, 194, 211, 0.25);

.flow-graph-container {
  position: relative;
}

.add-task-inline-container {
  position: relative;
  white-space: initial;

  .add-task-inline {
    &:not(.node),
    &.node:not(.is-lone):not(.is-read-only) {
      background-color: @secondary-500-base;
      color: @white;
      border-color: @secondary-500-base;

      &:hover,
      &:focus {
        background-color: @secondary-600 !important;
        color: @white;
        border-color: @secondary-600;
      }
    }

    &.node:not(.is-lone):not(.is-read-only) {
      width: 50px;
      height: 50px;
      padding: 0px;
      border: none !important;
      border-radius: @border-round;
      box-shadow: 0px 3px 3px rgba(34, 37, 41, 0.08);
      justify-content: center;
    }

    &.node.is-read-only {
      cursor: default !important;
    }

    &.node.is-lone {
      > .add-icon {
        height: 32px;
        width: 32px;
        color: @white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        background-color: @secondary-500-base;

        > svg {
          width: 50%;
          height: 50%;
          > path {
            fill: currentColor;
          }
        }
      }

      &:hover .add-icon {
        background-color: @secondary-600 !important;
      }
    }
  }
}

.node {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: @white;
  box-shadow: @box-shadow;
  border: 2px solid @transparent;
  border-radius: @border-radius-large;
  padding: 8px;
  height: 70px;
  width: 304px;

  &.disabled {
    background-color: @neutral-50;

    & > img,
    & > h4 {
      opacity: 0.5;
    }
  }

  > h4 {
    display: flex;
    flex-direction: column;
    color: @neutral-800;
    font-size: 14px;
    line-height: 18px;
    margin-left: 10px;

    .font-medium + .truncated::before {
      content: ' - ';
      white-space: pre-wrap;
    }
  }
}

.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: -9px;
  right: -9px;
  width: 18px;
  height: 18px;

  color: @white;
  background-color: @neutral-400;
  box-shadow: @flow-box-shadow;
  border-radius: @border-radius;

  &:hover,
  &:focus {
    background-color: @neutral-500-base;
  }

  > svg {
    > path {
      fill: currentColor;
    }
  }
}

.grid-background {
  background-image: url(../images/flow-dots-bg.svg);
  background-position-x: -5px;
  background-position-y: -5px;
}

.flow-container {
  position: absolute;
  min-height: -webkit-fill-available;
  left: 0;
  right: 0;

  * {
    box-sizing: border-box !important;
  }

  .row-dropdown .dropdown-menu {
    padding: 6px 0;
  }
}

.flow-builder {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 50px 240px;

  > svg {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;

    path {
      stroke: @neutral-400;
      stroke-width: 2px;
    }
  }
}

.flow-builder--node {
  &:not(.drag-target) {
    position: relative;
  }

  &.is-dragged {
    z-index: 10;

    & > .flow-builder--content > .node {
      cursor: grabbing !important;
    }
  }

  &:focus-within,
  &:focus,
  &:hover {
    &.with-border {
      outline: none;

      > .flow-builder--content > .node:not(.add-task-inline) {
        border: 2px solid @border-color;
        background-color: @neutral-50;
        cursor: pointer;
      }
    }

    > .flow-builder--content > .node .task-actions {
      opacity: 1;
    }
  }

  &.with-border:focus-within > .flow-builder--content > .node:not(.is-read-only) {
    border-color: @secondary-500-base !important;
  }

  &.run-specific-rows .node > h4 > span:first-child,
  &.continue-on-failure .node > h4 > span:first-child {
    padding-right: 20px;
  }

  &.run-specific-rows.continue-on-failure .node > h4 > span:first-child {
    padding-right: 46px;
  }

  &.has-deleted-configuration > .flow-builder--content > .node {
    border: 2px solid @error-500-base !important;
    background-color: @neutral-50;
  }

  &.has-blank-configuration > .flow-builder--content > .node {
    border: 2px solid @warning-500-base !important;
    background-color: @neutral-50;
  }

  .simple-icon {
    z-index: 1;
    position: absolute;
    right: 12px;
    top: 12px;
    border-radius: @border-round;

    &:has(+ .simple-icon) {
      right: 40px;
    }
  }
}

.flow-builder--group {
  position: relative;
  transform: none;
  margin: 20px 30px 54px;

  &.is-first {
    margin-top: 0;
  }

  &.is-last {
    margin: 0;

    .flow-builder--io {
      &.flow-builder--io-top {
        top: -12px;
      }

      &.flow-builder--io-bottom {
        bottom: -12px;
      }
    }
  }

  &:not(.is-last) {
    min-width: 340px;
    padding: 16px;
    border: 2px solid @neutral-200;
    border-radius: @border-radius;

    &:has(+ .flow-builder--group)::before {
      content: '';
      position: absolute;
      top: calc(100% + 2px);
      left: calc(50% - 1px);
      width: 2px;
      height: 54px;
      display: inline-block;
      background-color: @neutral-200;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      top: -31px;
      left: -31px;
      width: calc(100% + 62px);
      height: calc(100% + 62px);
      display: inline-block;
    }
  }

  .flow-builder--io.flow-builder--io-top {
    top: -30px;

    & > .flow-builder--io-port > .between-phases-action {
      position: absolute;
      left: 0;
      bottom: 5px;
      width: 100%;
      height: 52px;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      .circle-button {
        .fade-transition(all, 120ms);
        opacity: 0;
      }

      .circle-button.active,
      &:hover .circle-button,
      &:focus-within .circle-button {
        opacity: 1;
      }
    }
  }

  .merge-into-label {
    display: flex;
    flex-wrap: nowrap;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-left: 8px;
    padding-right: 8px;
    height: 28px;

    color: @white;
    background-color: @secondary-500-base;
    box-shadow: @flow-box-shadow;
    border-radius: @border-radius;

    font-style: normal;
    line-height: 28px;
    text-align: center;
  }

  &.merge-into,
  &.merge-into .phase-output-label {
    border: 2px solid @secondary-500-base;
  }

  &.merge-before::after {
    border-top: 2px dashed @secondary-500-base;
  }

  &.merge-after::after {
    border-bottom: 2px dashed @secondary-500-base;
  }

  > .flow-builder--content {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    justify-content: center;
    gap: 16px;

    > .tasks-count {
      position: absolute;
      top: -9px;
      left: 8px;
      line-height: 16px;
      padding: 0 8px;
      background-color: @neutral-50;
    }

    .phase-label {
      position: absolute;
      max-width: min(60%, 520px);
      z-index: 0;
      top: -18px;
      padding: 0 8px;
      color: @white;
      background-color: @neutral-800;
      border-radius: @border-radius;
      line-height: 24px;
      font-weight: 500;

      &.clickable:hover {
        text-decoration: underline;
        text-underline-offset: 2px;
      }
    }
  }

  .flow-builder--content > .phase-actions.active,
  &:hover .flow-builder--content > .phase-actions {
    opacity: 1;
  }

  .actions {
    .fade-transition(all, 120ms);
    opacity: 0;
    position: absolute;
    display: flex;
    gap: 8px;
    right: 16px;

    &.phase-actions {
      top: -18px;
    }

    &.task-actions {
      bottom: -16px;
    }
  }

  .circle-button {
    width: 32px;
    height: 32px;
    border: 1px solid @neutral-100;
    box-shadow: 0px 3px 3px rgba(34, 37, 41, 0.08);

    &:not(.add-task-inline) {
      background-color: @white;
    }

    .svg-inline--fa {
      font-size: 14px;

      &.fa-down-from-dotted-line {
        font-size: 16px;
        bottom: -1px;
      }
    }
  }

  .phase-output-label {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 8px;
    background: @neutral-50;
    border: 2px solid @neutral-200;
    border-radius: @border-radius;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;

    .svg-inline--fa {
      margin-right: 6px;
    }
  }
}

.flow-builder--io-port {
  background-color: @transparent;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .fa-caret-down {
    color: @neutral-200;
  }
}

.notification-row {
  padding: 10px 2px;

  &:first-child {
    margin-top: 10px;
  }

  &:last-child {
    padding-bottom: 0;
  }

  &:not(:last-child) {
    border-bottom: @border;
  }

  .btn-link {
    padding: 8px;
  }
}

@keyframes spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  position: relative;
  pointer-events: none;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: -5px;
    bottom: -5px;
    left: -5px;
    right: -5px;

    border-radius: 100%;
    background-image: conic-gradient(
      from 0deg at 50% 50%,
      transparent 76.87deg,
      @secondary-500-base 360deg
    );

    animation: spinning 2s linear infinite;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: -2px;
    bottom: -2px;
    left: -2px;
    right: -2px;
    border-radius: 100%;
    background: @white;
  }
}

.task-icon,
.task-status-spinner {
  margin-right: 12px;
}

.task-status-spinner .task-icon {
  margin-right: 0;
}

.task-icon-wrapper {
  position: relative;

  > .task-sub-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    background: @white;
    border-radius: 100%;
  }
}

.retry-modal {
  .modal-header {
    border-bottom: none;
    padding-bottom: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
  .modal-content {
    padding: 20px 0 20px 0;
  }
  .modal-footer {
    padding: 32px 20px 0px 20px;
  }
  .modal-body {
    padding-left: 0;
    padding-right: 0;
  }
}

.retry-table {
  .header-row,
  .task-row {
    display: grid;
    grid-template-columns: 32px auto 125px;
    align-items: center;
    padding: 8px 20px;
  }

  .header-row {
    font-weight: 500;
    padding-bottom: 12px;
    margin-bottom: 15px;
    box-shadow: @table-box-shadow;
  }

  .phase-row {
    display: grid;
    grid-template-columns: 32px auto;
    align-items: center;
    padding: 8px 20px;

    .label-row {
      position: relative;
      text-align: center;

      &::after {
        content: '';
        position: absolute;
        bottom: 50%;
        left: 0;
        right: 0;
        height: 0px;
        border-top: 1px dashed @neutral-200;
      }

      .label {
        position: relative;
        display: inline-block;
        font-size: 11px;
        line-height: 13px;
        font-weight: 500;
        background: @neutral-50;
        padding: 8px 11px;
        text-transform: uppercase;
        border-radius: 15px;
        z-index: 1;
      }
    }
  }

  .task-row {
    &:hover {
      background-color: @table-bg-hover;
    }

    .task-info h4 > span {
      &:first-child {
        line-height: 24px;
      }

      &:last-child {
        line-height: 16px;
      }
    }
  }
}

.flow-navigation-controls {
  display: inline-flex;

  .navigation-control {
    display: inline-flex;
    white-space: pre-wrap;
    margin-right: 22px;
  }

  .truncated {
    max-width: 250px;
  }

  @media all and (max-width: @screen-xl) {
    .truncated {
      max-width: 175px;
    }
  }

  @media all and (max-width: @screen-lg) {
    .navigation-control {
      display: initial;

      .switch-wrapper {
        white-space: initial !important;
      }
    }
  }
}

.flow-panel {
  padding: 58px 0 48px 0;
  min-height: 100vh;

  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #00000050;
  }

  .top-buttons .btn-separator:first-child {
    display: none;
  }

  & > .flow-panel-container {
    position: relative;
    background-color: @neutral-50;
    box-shadow: @modal-box-shadow;
    border-radius: @border-radius-large;
    padding: 0;

    @media (min-width: @screen-sm) {
      width: calc(@container-sm + 54px);
    }

    @media (min-width: @screen-md) {
      width: calc(@container-md + 54px);
    }

    @media (min-width: @screen-lg) {
      width: calc(@container-lg + 54px);
    }

    @media (min-width: @screen-xl) {
      width: calc(@container-xl + 54px);
    }

    @media (min-width: @screen-xxl) {
      width: calc(@container-xxl + 54px);
    }

    .container-topbar {
      background-color: @white;
      margin-bottom: 20px;

      .breadcrumb-component-icon .type-icon {
        border-color: @white !important;
        background: @white !important;
      }
    }

    #configuration-nav {
      background-color: @white;
      margin-top: -20px;
      margin-left: -15px;
      margin-right: -15px;
      padding-left: 15px;
      padding-right: 15px;

      @media (min-width: @screen-sm) {
        margin-left: -42px;
        margin-right: -42px;
        padding-left: 42px;
        padding-right: 42px;
      }
    }
  }

  .main-container {
    padding-bottom: 24px !important;
  }
}

.phase-label-tooltip {
  max-width: 480px;
  padding: 16px;
  line-height: 20px;
  text-align: left;
  background: @neutral-800;

  .phase-label-name {
    font-size: 14px;
    font-weight: 500;

    &:has(+ .phase-label-description) {
      margin-bottom: 7px;
    }
  }

  .phase-label-description {
    color: @neutral-100;
  }
}
