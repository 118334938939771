.box {
  background: @white;
  box-shadow: @box-shadow;
  border: none;
  border-radius: @border-radius-large;

  &.no-shadow {
    box-shadow: none;
  }

  &.searchbar-results-box {
    li {
      &:first-of-type {
        margin-top: -5px;
      }

      &:last-of-type {
        margin-bottom: -5px !important;
      }

      > * {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }

  &:not(.box-panel):not(.in-box) + &:not(.box-panel):not(.in-box) {
    margin-top: @box-margin;
  }

  .box-content {
    padding: @padding-base*5 @padding-base*6;

    &.small-padding {
      padding: 14px @padding-base*6;
    }

    &.with-panel {
      padding-bottom: 8px;
    }

    &.only-with-panel {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    & > .form-group:last-child {
      margin-bottom: 0;
    }

    .help-block:last-child {
      margin-bottom: 0;
    }
  }

  &.panel-info {
    margin-bottom: @box-margin;

    .box-content {
      padding: 0px;

      & > .flex-container:first-child {
        padding: 16px 20px 16px 24px;
      }

      .panel-info-content {
        padding: 0px 24px 16px;
      }
    }

    .panel-info-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-right: 32px;

      & > .text-muted {
        margin-right: 12px;
      }

      &.aside {
        padding-left: 32px;
        border-left: @border;
      }

      a,
      .btn {
        line-height: inherit;
      }

      .fa-bars-staggered {
        position: relative;
        bottom: -1px;
      }

      @media (min-width: @screen-xl) {
        flex-direction: row;
        align-items: center;
        padding-right: 24px;

        &.aside {
          padding-left: 24px;
        }
      }

      @media (max-width: @screen-lg) {
        padding-right: 12px;

        &.aside {
          padding-left: 12px;
        }
      }
    }
  }

  &.no-component-found {
    padding: 32px;

    .box-header {
      padding: 0;
      min-height: initial;

      .box-title {
        line-height: 24px;
      }
    }

    .box-content {
      padding: 16px 0 0 0;
      max-width: 480px;
      margin: 0 auto;

      p:last-of-type {
        margin-bottom: 24px;
      }
    }
  }

  .box-description-header {
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    margin-bottom: 10px;
    color: @neutral-400;

    & + p {
      margin-bottom: 0;
    }
  }

  .summary-title {
    color: @neutral-900;
    font-size: 21px;
    font-weight: 500;
    margin-bottom: 0;

    @media all and (min-width: @screen-md-min) {
      font-size: 26px;
    }

    @media all and (min-width: @screen-lg-min) {
      font-size: 32px;
    }
  }

  .summary-text {
    font-size: 14px;
    color: @neutral-400;
  }
  &.box-compact {
    .box-header {
      min-height: 50px;
      line-height: 50px;
      padding: 0 4px 0 20px;
      h2 {
        font-size: @font-size-base;
        color: @neutral-400;
        margin: 5px 0 0 0;
      }
    }
    .box-content {
      padding: 0 20px 10px;
    }
  }

  &.like-action-sidebar {
    background: rgba(240, 243, 245, 0.6);
    border: @border;

    .box-content {
      padding: 22px 26px 26px 26px;
    }
  }

  &.no-mapping {
    background: @neutral-50;
    box-shadow: none;
    border-radius: @border-radius-large @border-radius-large 0px 0px;
    border: @border;

    &.bottom-radius {
      border-bottom-left-radius: @border-radius-large;
      border-bottom-right-radius: @border-radius-large;
    }

    .box-header {
      margin-bottom: 0px;
      box-shadow: none;

      .box-title {
        color: @neutral-400;
      }
    }
  }

  &.disabled {
    .box-header.btn-collapse-area {
      cursor: initial;
    }

    .box-title {
      opacity: 0.4;
    }
  }

  &.in-modal {
    border: 1px solid rgba(135, 147, 159, 0.1);
    border-radius: 6px;

    .box-content {
      padding: 21px 20px 12px 20px;
    }
  }

  &.latest-purchases {
    margin-top: @box-margin;

    .above-table {
      min-height: 50px;

      .box-title {
        line-height: 20px;
      }

      & + .table-responsive .table th {
        padding-top: 4px;
      }
    }
  }

  .is-sticky {
    border-radius: @border-radius-large;
  }
}

.box,
.box-separator {
  .form-horizontal .form-group:last-child,
  .panel-show-details:last-child {
    margin-bottom: 0;
  }
}

.box-panel {
  display: flex;
  flex-direction: column;
  margin-bottom: @box-margin;
  padding: 0 @padding-base*3 @padding-base*3 @padding-base*3;
  position: relative;

  @media all and (min-width: @screen-sm-min) {
    padding: 0 18px 17px 18px;
  }

  .box-panel-content {
    padding: 0 @padding-base*2 0 @padding-base*3;

    &.padding-bottom {
      padding-bottom: 10px;
    }
  }

  .block-button {
    margin-top: auto;
    padding: 0;
    width: 100%;
  }
}

.box-header {
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: @padding-base*3;
  padding-right: @padding-base*2;

  &.with-border {
    border-bottom: @border;
  }

  &.with-shadow {
    box-shadow: @table-box-shadow;
  }

  &.smaller {
    min-height: auto;
  }

  &.big-padding {
    min-height: 64px;
    padding-left: @padding-base*6;
    padding-right: @padding-base*6;

    &.smaller-height {
      min-height: 51px;

      .box-title {
        margin-top: 10px;
      }
    }
  }

  &.btn-collapse-area .btn-collapse-trigger {
    position: relative;
    right: -8px;
  }

  &.above-table {
    min-height: 60px;
    padding: 20px 20px 0px 28px;

    .box-title {
      line-height: 40px;
    }

    + .table,
    + .table-responsive {
      th,
      tr,
      .th,
      .tr {
        &:first-of-type {
          padding-left: @padding-base * 7 !important;
        }

        &:last-of-type {
          padding-right: @padding-base * 7 !important;
        }
      }
    }
  }

  &.above-table-with-buttons {
    min-height: 64px;
    padding: 11px 4px 11px 24px;
    box-shadow: @table-box-shadow;

    &.old-transformation {
      padding: 11px 12px 11px 28px;
    }

    > .btn-success {
      margin-right: 4px;
    }

    & + div {
      margin-top: 4px;
    }
  }

  &.with-big-icon {
    min-height: 92px;
    padding-top: 4px;
    padding-left: 6px;

    .circle-icon {
      width: 48px;
      min-width: 48px;
      height: 48px;
    }

    .btn {
      &[disabled] {
        background: @neutral-400;
      }
    }

    &.with-border + .box-panel-content {
      padding: 3px 6px 0px 6px;
    }
  }

  .box-title {
    font-size: 16px;
    margin: 0;

    a {
      color: @neutral-800;
    }
  }

  &.with-border + .box-panel-content {
    margin-top: @padding-base*3;
    padding: @padding-base*3 @padding-base*2 @padding-base*3 @padding-base*3;
  }

  .box-filters {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .box-search {
    width: 200px;
    display: inline-block;
    margin: 0;

    &.box-search-wider {
      width: 334px;
    }

    .searchbar-input {
      font-size: 11px;
      font-weight: 600;
      letter-spacing: @letter-spacing-wider;
    }
  }

  .box-filter {
    margin-left: @padding-base*3;
  }

  .Select.box-filter {
    width: 200px;

    .react-select__control {
      background-color: @white;
      border: @border;
    }

    .react-select__single-value {
      font-size: 11px;
      font-weight: 600;
      color: @neutral-400;
      letter-spacing: @letter-spacing-wider;
      text-transform: uppercase;
    }
  }

  & > .btn-toolbar {
    display: inline-flex;
    align-items: center;
  }
}

.box-panels-row {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column;
  gap: @box-margin;

  @media all and (min-width: @screen-sm-min) {
    flex-direction: row;
  }

  & > .box-panel {
    flex: 1;
  }
}

.box-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -16px;
  margin-right: -16px;

  .box {
    flex-basis: 300px;
    flex-grow: 1;
    margin-left: 16px;
    margin-right: 16px;
    min-width: 403px;

    @media all and (min-width: @screen-sm-min) {
      min-width: 394px;
      max-width: 394px;
    }

    @media all and (min-width: @screen-md-min) {
      max-width: 504px;
    }

    @media all and (min-width: @screen-lg-min) {
      max-width: 604px;
    }

    @media all and (min-width: @screen-xl) {
      min-width: 494px;
      max-width: 804px;
    }

    @media all and (min-width: @screen-xxl) {
      min-width: 694px;
      max-width: 1004px;
    }

    &.empty {
      display: block;
      height: auto;
      min-height: 166px;
      background: rgba(200, 202, 217, 0.1);
      box-shadow: none;
      padding: 25px 53px 0 53px;
      text-align: center;
    }
  }

  &.four-columns,
  &.three-columns,
  &.two-columns {
    display: grid;
    gap: @box-margin;
    margin: 0;

    & > .box {
      min-width: initial;
      max-width: initial;
      padding: 0px 18px 24px 18px;
      margin: 0 !important;

      .box-header {
        padding: 4px 6px 0px 6px;
      }

      .box-panel-content {
        padding: 0px 6px;
      }
    }

    @media all and (min-width: @screen-md-min) {
      grid-template-columns: 1fr 1fr;
    }

    &.three-columns {
      grid-template-columns: 1fr 1fr;

      @media all and (min-width: @screen-md-min) {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }

    &.four-columns {
      grid-template-columns: 1fr 1fr;
      gap: 16px;

      @media all and (min-width: @screen-md-min) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }
  }

  .vertical-boxes {
    display: grid;
  }
}

.box-branch-description {
  .box-panel-content {
    overflow-y: auto;
    max-height: 330px;
  }
}

.account-plan-box .box-panel-content > * {
  &:not(:last-child) {
    margin: 0 0 16px 0;
  }
}

.project-settings {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: @box-margin;
  padding: 32px;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .well {
      margin: 0;
    }
  }
}
