.container-topbar {
  background-color: @transparent;
  border: none;
  margin: 0;

  & > .container-fluid {
    padding: 0;
  }

  .topbar {
    background: @white;
    padding: 13px 0 11px 0;
    border-bottom: @border-light;

    .topbar-left {
      float: left;
      display: flex;
      align-items: center;
    }

    .topbar-right {
      float: right;
      display: flex;
      align-items: center;
    }

    .navbar-toggle {
      float: right;
      background-color: transparent;
      background-image: none;
      border: 1px solid @neutral-150;
      border-radius: 4px;
      padding: 9px 10px;
      position: relative;

      .icon-bar {
        display: block;
        width: 22px;
        height: 2px;
        border-radius: 1px;
        background-color: @neutral-400;
      }

      .icon-bar + .icon-bar {
        margin-top: 4px;
      }

      &:hover {
        background-color: @neutral-100;
      }

      @media (min-width: @screen-sm-min) {
        display: none;
      }
    }

    #navbar-settings + .dropdown-menu {
      padding: 14px 20px;
    }

    #select-project-dropdown,
    #select-branch-dropdown {
      height: 42px;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: normal;
      padding: 0px 10px 0px 0px;
      display: flex;
      align-items: center;
      border: none;
      text-decoration: none;
      text-transform: initial;

      .project-name,
      .branch-name {
        display: block;
        height: 100%;
        font-weight: 400;
        text-align: left;
        margin-left: 15px;
        margin-right: 5px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        span {
          display: block;
          color: @neutral-300;
        }

        strong {
          font-size: 16px;
          font-weight: 500;
          color: @neutral-800;
        }
      }

      &.dropdown-toggle > .branch-name {
        display: flex;
        align-items: center;
        max-width: 360px;

        > div {
          margin-right: 5px;
        }
      }

      + .dropdown-menu {
        top: ~'calc(100% + 7px)';
        left: 0;
        margin: 0;
        min-width: 280px;
        max-width: 300px;

        .searchbar {
          margin: 0;
          padding: 12px;
          border-radius: @border-radius;
        }

        .searchbar-input {
          padding-left: 44px;
          font-weight: 400;
        }

        .fa-magnifying-glass {
          left: 18px;
        }
      }
    }

    #select-project-dropdown {
      max-width: 280px;

      + .dropdown-menu {
        left: 5px;
      }

      .project-name {
        display: flex;
        align-items: center;
        max-width: 250px;

        > div {
          margin-right: 5px;
        }
      }
    }
  }

  .main-navbar {
    height: auto;
    background: @white;
    box-shadow: 0px 4px 8px rgba(86, 86, 90, 0.05);

    #navbar-flows,
    #navbar-components,
    #navbar-transformations {
      background: @white;

      &.btn.dropdown-toggle {
        &:focus {
          .tab-focus();
        }
      }

      & + .dropdown-menu {
        padding: 14px 0px;
        margin: 0;
        border-radius: 6px;
        top: ~'calc(100% - 5px)';

        & > li {
          & > a {
            font-size: 16px;
            line-height: 19px;
            color: @neutral-400;
            padding: 7px 20px 6px 20px;

            .icon-addon-right {
              margin-right: 14px;
            }
          }

          & > a:hover,
          & > a:focus,
          & > a:active,
          &.active a {
            background: @white;
            color: @neutral-800;
            outline: none;
          }
        }
      }
    }

    .navbar-collapse {
      border-top: 0;
      margin-right: -15px;
      margin-left: -15px;

      @media (min-width: @screen-sm-min) {
        margin-right: 0;
        margin-left: 0;
      }

      &.collapse {
        display: grid;
        grid-template-rows: 0fr;
        transition: grid-template-rows 500ms;

        &:before {
          display: none;
        }

        &.in {
          grid-template-rows: 1fr;
        }

        @media (min-width: @screen-sm-min) {
          display: block;
        }
      }

      .navbar {
        border: none;
        display: flex;
        overflow: hidden;

        @media (min-width: @screen-sm-min) {
          min-height: 50px;
          overflow: visible;
        }

        .navbar-nav {
          margin-top: 8px;
          margin-bottom: 8px;

          @media (min-width: @screen-sm-min) {
            display: flex;
            flex-wrap: wrap;
            line-height: 20px;
            margin-top: 0;
            margin-bottom: 0;
          }

          @media not all and (min-width: @screen-sm-min) {
            .open .dropdown-menu {
              float: none;
              box-shadow: none;
              border: 0;
              position: static;
            }
          }
        }

        @media (min-width: @screen-sm-min) {
          li:first-of-type > .navbar-link {
            padding-left: 0;
          }
        }

        .navbar-link {
          font-size: 16px;
          font-weight: 400;
          letter-spacing: normal;
          color: @neutral-400;
          background: transparent;
          display: flex;
          align-items: center;
          text-transform: initial;
          text-decoration: none;
          transition: color 100ms ease-in;
          height: 42px;
          padding-top: 12px;
          padding-bottom: 12px;

          @media (min-width: @screen-sm-min) {
            height: 67px;
            flex-direction: column;
            justify-content: space-between;
            padding: 14px 10px;

            svg.icon-addon-right {
              margin-right: 0;
              margin-bottom: 0;
            }
          }

          @media (min-width: @screen-md-min) {
            padding: 0 8px;
            font-size: 15px;
            flex-direction: row;
            justify-content: flex-start;

            svg.icon-addon-right {
              margin-right: 4px;
            }
          }

          @media (min-width: @screen-lg-min) {
            padding: 0 14px;

            svg.icon-addon-right {
              margin-right: 8px;
            }
          }

          svg {
            margin-bottom: 0;

            @media (min-width: @screen-md-min) {
              font-size: 18px;
            }

            @media (min-width: @screen-lg-min) {
              font-size: 22px;
            }

            &.fa-warehouse {
              @media (min-width: @screen-md-min) {
                font-size: 16px;
              }

              @media (min-width: @screen-lg-min) {
                font-size: 20px;
              }
            }
          }

          &:not(.btn-disabled) {
            &:hover,
            &:focus,
            &.active {
              color: @secondary-500-base;
            }
          }
        }
      }
    }
  }

  .topbar-navbar {
    height: 40px;
    display: flex;
    align-items: center;
    margin-right: 10px;

    & > span > a,
    & > span > .btn,
    & .dropdown-toggle {
      font-size: 24px;
      color: @neutral-400;
      display: flex;
      align-items: center;
      padding: 8px 10px;
      transition: all 100ms ease-in;

      &:hover,
      &:focus {
        color: @secondary-500-base;
      }

      &#platform-news {
        .news-icon {
          svg.fa-gift {
            bottom: 0;
          }

          .unread-count {
            position: absolute;
            top: 3px;
            right: 2px;
            text-align: center;
            line-height: 12px;
            height: 20px;
            min-width: 20px;
            padding: 2px;
            background-color: @error-500-base;
            border: 2px solid @white;
            border-radius: @border-round;
            font-size: 11px;
            font-weight: 600;
            color: @white;
          }
        }

        & ~ .dropdown-menu {
          min-width: 295px;
          padding: 20px;

          a {
            position: relative;

            .unread:before {
              content: '';
              position: relative;
              display: inline-block;
              width: 8px;
              height: 8px;
              margin-right: 6px;
              background-color: @secondary-500-base;
              border-radius: @border-round;
            }

            &:hover > span:nth-child(2) {
              color: @secondary-500-base;
            }
          }
        }
      }
    }
  }
}

.top-sticky-bar {
  position: sticky;
  top: 0;
  height: 56px;
  display: flex;
  z-index: 100;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 100px;
  background: linear-gradient(90.09deg, #3e9eff 0%, #0975e0 100%);
  color: @white;

  & + .top-sticky-bar {
    top: 56px;
  }

  &.warning {
    background: linear-gradient(90.09deg, #fff3e0 0%, #ffe7c2 100%);
    color: @neutral-800;
    border-bottom: #ffd699 1px solid;
  }

  .btn,
  .btn:hover {
    margin-left: 32px;
    margin-right: 32px;
    padding: 6px 16px;
  }
}
