.project-select,
.branch-select {
  &.disabled > #select-project-dropdown,
  &.disabled > #select-branch-dropdown {
    cursor: default;
    opacity: 1;
  }

  &.new-branch-button #select-branch-dropdown .branch-name strong {
    color: @neutral-400;
    padding: 5px 0;

    &:hover,
    &:focus {
      color: @primary-500-base;
      text-decoration: none;
    }
  }

  .no-results-found {
    font-size: 14px;
    margin: 15px;
  }

  .no-project-found {
    padding: 8px 10px;
  }

  .list-unstyled {
    padding: 8px;

    hr {
      margin: 8px 0;
    }

    li {
      & > a,
      .active,
      .disabled,
      .btn-link-inline:not(.text-muted) {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 8px;
        border-radius: @border-radius;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: @neutral-800;
        text-decoration: none;
        white-space: pre-wrap;

        &.active {
          background-color: @neutral-100;
        }

        &.disabled {
          cursor: not-allowed;
          color: @neutral-300;
        }

        svg:not(.fa-pen):not(.fa-trash) {
          bottom: -1px;
          width: 16px;

          &.fa-plus {
            font-size: 15px;
          }
        }
      }

      &:not(.organization-item) > *:hover:not(.disabled) {
        background-color: @neutral-100;
      }

      &.branch-item {
        a,
        .active {
          .fa-code-branch {
            position: relative;
            vertical-align: middle;
            font-size: 16px;
            flex-shrink: 0;
          }

          .branch-info,
          .branch-buttons {
            display: inline-flex;
            margin-left: auto;
            gap: 16px;
          }

          .branch-buttons {
            display: none;

            .btn {
              padding: 0px 4px;
            }
          }

          &:hover {
            .branch-info {
              display: none;
            }

            .branch-buttons {
              display: inline-flex;
            }
          }
        }
      }

      &.organization-item {
        padding-left: 8px;
        padding-right: 8px;

        &:first-of-type {
          padding-top: 2px;
        }

        &:last-child {
          padding-bottom: 8px;
        }

        & > hr {
          margin: 10px 0;
        }
      }
    }
  }
}
