.breadcrumb-container {
  min-height: 104px;
  padding: 17px 15px 13px 15px;

  // display a smaller input multiple button groups are present
  &:has(.btn-toolbar + .btn-separator + .btn-toolbar) {
    .title h1 .inline-edit-input .inline-edit-input-control {
      max-width: 350px;

      @media (min-width: @screen-xl) {
        max-width: 540px;
      }
    }
  }

  .title {
    min-height: 54px;
    position: relative;
    margin: 0 auto 0 0;
    display: flex;

    h1 {
      color: @neutral-900;
      word-break: break-all;
      font-size: 32px;
      line-height: 36px;
      font-weight: 400;
      display: flex;
      align-items: center;
      margin: 0 15px 0 0;

      & > span {
        display: flex;
      }

      &.reduce-size {
        margin-right: 0;

        .inline-edit-input {
          .inline-edit-input-control {
            max-width: 300px;

            @media (min-width: @screen-lg-min) {
              max-width: 480px;
            }

            @media (min-width: @screen-xl) {
              max-width: 670px;
            }
          }
        }
      }

      .inline-edit-input-text {
        @apply tw-line-clamp-2;
      }

      .inline-edit-input {
        height: 36px;
        top: -1px;
        margin-right: -25px;

        .inline-edit-input-control {
          max-width: 625px;
        }

        & > .btn {
          left: -20px;
          display: inline-block;
        }
      }
    }

    @media (min-width: @screen-xl) {
      h1 .inline-edit-input .inline-edit-input-control {
        max-width: 825px;
      }
    }

    @media (min-width: @screen-xxl) {
      h1 .inline-edit-input .inline-edit-input-control {
        max-width: 1225px;
      }
    }

    .breadcrumb-component-icon {
      align-self: center;
      margin: 0px 15px 0px 1px;
      height: fit-content;

      img {
        margin-top: 1px;
        display: inline-block;
        max-width: 48px;
        height: auto;
      }

      &.component-icon-with-type .type-icon {
        border-color: @neutral-50;
        background: @neutral-50;
      }
    }
  }

  .job-status-label,
  .workspace-status-label {
    display: inline-block;
    font-size: 11px;
    line-height: 12px;
    font-weight: 600;
    color: @white !important;
    background-color: @neutral-800;
    border-radius: @padding-base;
    letter-spacing: @letter-spacing-wider;
    padding: 6px 8px;
    text-align: center;
    text-transform: uppercase;
  }

  .job-status-label.text-muted {
    background-color: @neutral-400;
  }

  .job-status-label.color-primary {
    background-color: @secondary-500-base;
  }

  .job-status-label.text-success,
  .workspace-status-label.text-success {
    background-color: @primary-500-base;
  }

  .job-status-label.color-warning {
    background-color: @warning-600;
  }

  .job-status-label.text-danger {
    background-color: @error-500-base;
  }
}

.breadcrumb-container .breadcrumb,
.full-screen-modal .breadcrumb {
  background: @transparent;
  padding: 0;
  margin: 0 0 4px 0;
  font-weight: 400;

  &:empty {
    display: none;
  }

  &:not(:empty) {
    min-height: 20px;
  }

  .fa-angle-right {
    color: @neutral-400;
    font-size: 16px;
    margin: 0 12px;
  }

  & > a,
  & > .active,
  & > .btn-disabled {
    font-size: 16px;
    line-height: 18px;

    .svg-inline--fa {
      color: @neutral-400 !important;
      font-size: 13px;
      margin-right: 8px;
    }
  }

  & > span.active {
    color: @neutral-400;
  }
}

@media all and (max-width: @screen-xs-max) {
  .breadcrumb-container {
    padding: 25px 20px 15px 15px;
  }
}
