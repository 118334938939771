.table.react-table {
  & > .thead > .tr > .th:first-of-type,
  & > .tbody > .tr > .td:first-of-type {
    padding-right: 0px;
  }

  & > .tbody > .tr > .td {
    padding: @table-cell-padding;

    .fa-folder,
    .fa-folder-open,
    .fa-table,
    .fa-cabinet-filing {
      font-size: 16px;
      margin-right: 10px;
    }

    .circle-icon .fa-folder {
      margin: 0;
    }

    &.bg-selected {
      .fa-folder > path,
      .fa-folder-open > path,
      .fa-table > path,
      .fa-cabinet-filing > path {
        fill: @secondary-500-base;
      }
    }

    &.dev-bucket {
      .fa-folder > path,
      .fa-folder-open > path,
      .fa-table > path,
      .fa-cabinet-filing > path {
        fill: @warning-500-base;
      }
    }

    & > .with-expand {
      min-height: 40px;
    }
  }
}
