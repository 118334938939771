#search-context-dropdown {
  color: @neutral-800 !important;
  font-weight: 600;
  border: @border;
  margin-right: 16px;

  &.active,
  &:active {
    border-color: @secondary-500-base;
    box-shadow: @outline-shadow;
    background: @transparent;
  }

  & + .dropdown-menu {
    background: @white;
    box-shadow: @box-shadow-secondary;
    width: 360px;
    max-height: 460px;
    padding: 8px;
    overflow-y: auto;
    top: 38px;
    left: 0;

    & > li {
      padding: 10px 7px 10px 8px;
      margin-bottom: 0;
      border-radius: @border-radius;

      &:not(.query-row):hover {
        background: @neutral-100;
      }

      &.query-row {
        border-bottom: @border;
        margin-bottom: 8px;
      }

      svg.color-primary {
        color: @secondary-500-base !important;
      }

      .fa-folder {
        color: @neutral-400;
      }

      .fa-folder-open {
        color: @neutral-400;
      }

      &.dev-bucket {
        .fa-folder,
        .fa-folder-open {
          color: @warning-500-base;
        }
      }

      a {
        white-space: normal;

        &.active,
        &:focus,
        &:hover {
          color: @secondary-500-base;
          background-color: transparent;
        }
      }
    }
  }
}

.dropdown.open #search-context-dropdown {
  z-index: 1001;
}
