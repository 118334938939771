div:has(> .react-flow) {
  --sidebar-width: 320px;

  @media all and (min-width: @screen-lg) {
    --sidebar-width: 360px;
  }

  @media all and (min-width: 1440px) {
    --sidebar-width: 400px;
  }

  @media all and (min-width: @screen-xl) {
    --sidebar-width: 440px;
  }

  [data-sidebar] > div {
    width: var(--sidebar-width);
  }

  [data-sidebar] + .react-flow .react-flow__controls {
    right: calc(var(--sidebar-width) + 24px) !important;
  }
}
