.flow-builder {
  display: block;
  position: relative;
  box-sizing: content-box;
  width: auto;
  max-width: 100%;
  height: 100%;
}

.flow-builder--node {
  position: absolute;
  display: inline-flex;
  flex-direction: row;
  background-color: transparent;
  transform: translate(-50%, -50%);
}

.flow-builder--content {
  width: 100%;
  height: 100%;
  display: inline-flex;
  flex-direction: row;
}

.flow-builder--group {
  position: absolute;
  display: inline-flex;
  flex-direction: row;
  background-color: transparent;
  transform: translate(-50%, -50%);
}

.flow-builder--group .flow-builder--node {
  position: static;
  transform: none;
}

.flow-builder--group .flow-builder--node .flow-builder--io {
  display: none;
}

.flow-builder--io {
  position: absolute;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 16px;
  margin: 0;
  left: 0;
  pointer-events: none;
  background-color: transparent;
}

.flow-builder--io.flow-builder--io-top {
  top: -8px;
  bottom: auto;
}

.flow-builder--io.flow-builder--io-bottom {
  top: auto;
  bottom: -8px;
}

.flow-builder--io-port {
  position: static;
  margin: 0;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: red;
  pointer-events: all;
}
