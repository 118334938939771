.modal-open #dev-mode-bar {
  z-index: 1040;
}

.full-screen-modal-open #dev-mode-bar::before {
  height: 4px !important;
}

#dev-mode-bar {
  --background: @warning-500-base;
  position: fixed;
  height: 20px;
  z-index: 10001;
  top: 0;
  left: 0;
  right: 0;
  letter-spacing: 1px;
  color: @white;
  overflow: hidden;

  & + .container-topbar .topbar {
    padding-top: 33px;
  }

  &:not(.in-branch) {
    --background: @neutral-400;
  }

  &.in-review {
    --background: @accent-purple-500-base;
  }

  &.is-approved {
    --background: @primary-500-base;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    background: var(--background);
    height: 4px;
    transition: height 100ms ease-in-out;
  }

  &.static::before {
    height: 20px;
  }

  .dev-mode-label {
    position: absolute;
    left: 50%;
    border-radius: 0px 0px 4px 4px;
    padding: 2px 8px;
    pointer-events: none;
    background: var(--background);
    font-size: 11px;
    text-align: center;
    text-transform: uppercase;
    transition: transform 200ms ease-in;
    transform: translate(-50%, -100%);

    &.active {
      transform: translate(-50%, 0);
    }
  }

  &.static .dev-mode-label {
    width: max-content;
  }

  &:not(.static) {
    margin-bottom: -16px;

    .dev-mode-label span + span {
      display: none;
    }
  }
}
