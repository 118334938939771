[data-radix-popper-content-wrapper] {
  z-index: 1051 !important;
}

[data-tooltip-trigger] {
  display: flex;
  white-space: pre-wrap;

  &:has(> :where(button, a, span, svg):not(.btn-block):not(.tw-block):not(.tw-flex)) {
    display: inline-flex;
  }
}

[data-tooltip-content] {
  .tooltip-title {
    font-size: 11px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 8px;
    color: @neutral-300;
    text-transform: uppercase;
  }

  &.tw-text-white .tooltip-title {
    color: @white;
  }

  hr {
    margin: 12px 0;
    border-color: @neutral-600;
  }

  a,
  a:is(:hover, :focus) {
    color: inherit;
  }

  p:last-of-type:not(.tooltip-title) {
    margin-bottom: 0;
  }
}
