.split-diff {
  height: calc(100vh - 84px);
  background: @neutral-100;
  text-align: left;
  margin: 0 -20px;
  border-top: 1px solid @neutral-150;

  .diff-table {
    width: 100%;
    table-layout: fixed;
    background: @neutral-50;

    .diff-content,
    .diff-content-header {
      width: calc(50vw - 70px);
    }

    .diff-code-fold {
      height: 36px;
      background: @secondary-100;
      font-weight: 500;

      .btn-link-inline {
        color: @neutral-800;
      }
    }

    .diff-line {
      vertical-align: baseline;
    }

    tr > th {
      background: @white;
      font-weight: initial;
      border-bottom: 1px solid @neutral-150;
      padding: 12px 0;

      &:last-child {
        padding-right: 32px;
      }

      &:empty {
        width: 58px;
      }
    }

    .diff-added {
      background: @primary-100;

      &.diff-gutter {
        background: @primary-200;

        pre {
          color: @primary-600;
          white-space: pre-wrap;
          line-height: 24px;
        }
      }
    }

    .diff-removed {
      background: @error-100;

      &.diff-gutter {
        background: @error-200;

        pre {
          color: @error-500-base;
        }
      }
    }

    .diff-gutter {
      user-select: none;
      width: 58px;
      background: @neutral-100;
      padding: 0px 8px;
      white-space: nowrap;
      text-align: right;
    }

    .diff-empty-line {
      background: @neutral-50;
    }
  }

  pre {
    font-size: 14px;
    background-color: @transparent;
    border: none;
    border-radius: 0;
    padding: initial;
    margin: 0;

    &.diff-line-number {
      color: @neutral-400;
    }

    &.diff-content-text,
    &.diff-code-fold-content {
      color: @neutral-800;
      white-space: pre-wrap;
      line-height: 24px;
      padding: 0 8px;

      .diff-word-diff {
        padding: 2px;
        display: inline-flex;
      }

      .diff-word-added {
        background: @primary-200;
      }

      .diff-word-removed {
        background: @error-200;
      }
    }
  }
}
