.search-panel {
  margin-bottom: @box-margin;

  .searchbar {
    margin-bottom: 0;
    border: 1px solid @transparent;
    border-radius: @padding-base*2;

    .searchbar-input {
      height: 52px;
      padding: 18px 46px;
      border-radius: @padding-base*2;
      transition: none;
      border: none;
      box-shadow: none;

      &:placeholder-shown {
        padding-right: 15px;
      }
    }

    .searchbar-actions {
      margin-right: 16px;
    }

    .searchbar-clear-btn {
      top: 9px;
    }

    .fa-magnifying-glass {
      left: 20px;
    }

    &:has(.searchbar-form:focus-within) {
      border-color: @secondary-500-base;
      box-shadow: @outline-shadow;
      .transition(~'border-color .15s ease-in-out, box-shadow .15s ease-in-out');
    }

    .btn-sort {
      color: @neutral-500-base;

      &:hover,
      &:focus {
        color: @neutral-800;
      }
    }
  }
}
