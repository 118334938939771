.simplified-form {
  .step-help {
    position: relative;
    max-width: 430px;
    background: @neutral-50;
    border: 2px solid @neutral-100;
    border-radius: @border-radius-large;
    padding: 15px 30px 10px 30px;
    margin: 0;

    h3 {
      margin: 10px 0px;
      color: @neutral-800;
      font-size: 11px;
      font-weight: 600;
      letter-spacing: @letter-spacing-wider;
      text-transform: uppercase;
    }

    p {
      font-size: 13px;
      line-height: 20px;
    }

    &:before {
      content: '';
      top: 20px;
      bottom: auto;
      left: -6px;
      right: auto;
      position: absolute;
      z-index: 999;
      width: 10px;
      height: 10px;
      border-top: 2px solid @neutral-100;
      border-right: 2px solid @neutral-100;
      background: @neutral-50;
      transform: rotate(-135deg);
    }

    &.is-down {
      align-self: flex-end;

      &:before {
        top: auto;
        bottom: 15px;
      }
    }
  }

  .box-content > .flex-container .fill-space {
    display: flex;
    flex-direction: column;
    align-self: stretch;

    & > form.form-horizontal {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      button.btn-confirm {
        margin-top: auto;
      }
    }
  }

  .select-with-border {
    border-radius: @border-radius;
    border-color: @neutral-100;
  }
}

.empty-muted {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 240px;
  background: @neutral-50;
  border: 2px solid @neutral-100;
  border-radius: @border-radius;

  svg {
    color: @neutral-400;
  }

  p {
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: @letter-spacing-wider;
    text-transform: uppercase;
    color: @neutral-400;
    margin: 10px 0px 0px;
  }
}

.simplified-list {
  box-shadow: inset @table-box-shadow;
  padding-top: 4px;
}

.simplified-table-input-mapping,
.simplified-table-output-mapping {
  .bucket-row td {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .table-row td {
    padding-top: 1em;
  }

  .btn-success:first-child {
    margin: 8px 0 32px 0;
  }
}
