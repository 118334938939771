.user {
  @media all and (max-width: @screen-md-min) {
    margin-right: 15px;
  }

  .dropdown-menu {
    box-shadow: @box-shadow-secondary;

    a {
      padding: 8px;
      border-radius: 4px;
      color: @neutral-800;

      &:hover,
      &:focus {
        background: @neutral-100;
      }
    }
  }
}
