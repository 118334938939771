.modal-dialog {
  margin: 75px auto 30px auto;
}

.modal {
  .modal-content {
    padding: 20px 20px 16px 20px;
    box-shadow: @modal-box-shadow;
    border: none;
  }

  .modal-header {
    .close {
      position: relative;
      right: -10px;
      font-size: 23px;
      font-weight: 500;
      width: 28px;
      height: 28px;
      margin-top: -6px;
      color: @gray-medium;
      opacity: 1;
    }
  }

  .modal-body,
  .modal-footer {
    border: none;
  }

  .modal-body:has(.modal-action-button) {
    padding: 20px 0 0;
  }

  .modal-footer {
    padding: 12px 12px 14px 12px;
  }

  .modal-title {
    font-size: 16px;
    color: @gray-base;
  }

  .btn-toolbar .btn,
  .btn-toolbar .btn-group,
  .btn-toolbar .input-group {
    float: none;
  }

  &.only-with-editor .modal-footer {
    position: relative;
    z-index: 1;
  }

  &:not(.full-screen-modal):not(.retry-modal):not(.scheduler-modal):not(.scrollable-modal):not(
      .simple-modal-with-table
    ):not(.modal-with-side-content) {
    .modal-header {
      padding: 12px 0px 20px 0px;
      margin: 0 12px;
    }

    .modal-body {
      margin: 0 -20px;
      padding-left: 32px;
      padding-right: 32px;
      padding-bottom: 4px;
      max-height: calc(100vh - 210px);
      overflow-y: auto;

      &:has(+ .modal-footer) {
        max-height: calc(100vh - 270px);
      }
    }
  }

  &:has(table.stretch-modal) .modal-body {
    overflow-x: hidden;
  }
}

.modal:not(.full-screen-modal) {
  .overflow-break-anywhere();

  .modal-header .close {
    top: 2px;
  }

  .modal-sm {
    width: 480px;

    .modal-header {
      padding: 12px 5px 20px 5px;

      .modal-icon {
        left: 24px;
      }
    }

    .modal-body {
      padding: 16px 4px 0px 4px;
    }

    .modal-footer {
      padding: 16px 4px 0px 4px;
    }
  }

  .table-responsive {
    word-break: initial;
  }

  .btn-toolbar.block {
    display: flex;

    &.same-width > .btn {
      flex: 1;
    }

    .btn:first-of-type {
      margin-left: 5px;
    }

    button[type='submit'],
    .btn-success,
    .btn-primary {
      flex: 1;
    }

    .fa-arrow-left {
      color: @neutral-400;
    }
  }
}

.simple-modal-with-table {
  .modal-content {
    padding: 0;

    .modal-header {
      padding: 36px 32px 0px 32px;

      &:not(.no-border) {
        border-bottom: none;

        .modal-title {
          padding-bottom: 15px;
          border-bottom: @border-light;
        }
      }
    }
  }

  .modal-body {
    padding: 0;

    .table {
      padding: 5px 0px 8px 0px;

      & > .thead > .tr > .th,
      & > thead > tr > th {
        padding: 16px 32px 12px 32px;
        line-height: 24px;
      }

      & > .tbody > .tr > .td,
      & > tbody > tr > td {
        padding: 16px 32px;
        line-height: 24px;
      }
    }
  }
}

.modal-open > div[role='dialog'][aria-hidden='true'] .modal.fade.in {
  z-index: 1040;
}

.lineage-modal {
  .modal-content {
    padding: 0;
  }
}

.modal-with-side-content {
  .modal-content {
    padding: 0;
    overflow: hidden;

    & > form {
      display: flex;

      & > div {
        position: relative;

        &:first-of-type {
          display: flex;
          flex-direction: column;
          flex-shrink: 0;
          flex-basis: 384px;
          padding: 20px 0;
          border-right: @border;

          .modal-header,
          .modal-body,
          .modal-footer {
            padding-left: 24px;
            padding-right: 24px;
          }

          .modal-header {
            padding-top: 4px;
            padding-bottom: 15px;
            line-height: 24px;
          }

          .modal-body {
            overflow-y: auto;
            padding-bottom: 24px;

            .form-group:last-of-type {
              margin-bottom: 0;
            }
          }

          .modal-footer {
            margin-top: auto;
            padding-top: 24px;
            padding-bottom: 0;
            border-top: @border;
          }
        }

        &:last-child {
          flex-grow: 1;
          overflow: auto;
          background-color: @neutral-50;

          button.close {
            position: absolute;
            z-index: 10;
            top: 16px;
            right: 20px;
            padding: 5px;
            color: @neutral-400;
            opacity: 1;
            font-size: 23px;
          }

          & > div {
            height: 100%;
          }

          div.box {
            border-radius: 0;
            box-shadow: none;
          }

          div.table-responsive {
            flex-grow: 1;
            height: 100%;
            overflow-wrap: break-word;

            table {
              padding: 0 !important;

              thead tr th {
                padding-top: 56px !important;
                line-height: 24px;
                font-size: 16px;
              }
            }
          }
          &::after {
            content: '';
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            right: 0;
            height: 95px;
            background-color: @neutral-50;
          }

          & > * {
            z-index: 1;
          }
        }
      }
    }
  }
}

.modal-footer .btn-toolbar.multiple-buttons {
  margin-left: -12px;

  & > .btn {
    display: inline-block;
    width: calc(50% - 12px);
    margin-left: 12px;
  }
}
