.run-results-graph > svg {
  position: relative;
  overflow: initial;
}

.jobs-graph {
  position: relative;

  .chart-box:not(:empty) {
    margin: 0 48px 0 22px;
    padding-bottom: @box-margin;
  }

  .xAxis {
    zoom: reset;
  }

  .tick-date {
    transform-box: fill-box;
    transform-origin: center;
    transform: rotate(-45deg) translate(-12px, 12px);
    text-anchor: middle;
    fill: @neutral-500-base;

    tspan {
      font-size: 11px;
    }
  }

  @media all and (max-width: @screen-lg) {
    .tick-date {
      transform: rotate(270deg) translate(-14px, 14px);
      text-anchor: end;

      tspan {
        font-size: 10px;
      }
    }
  }

  button {
    position: absolute;
    z-index: 3;
    border-color: @neutral-150;
    margin-bottom: 50px;

    &:first-child {
      left: 8px;
    }

    &:last-child {
      right: 8px;
    }
  }

  svg {
    overflow: visible;
  }
}
