.files-dropzone {
  .dropzone-container {
    padding: 32px;
    border: 2px dashed @border-color;

    &:not(.disabled) {
      cursor: pointer;

      &.drag-enter {
        background: @secondary-100;
        border-color: @secondary-500-base;
      }

      &:hover,
      &:focus {
        border-color: @secondary-500-base;
      }
    }

    &:focus-visible {
      outline: none;
    }
  }

  .Select .react-select__control {
    border: @border;
    background-color: @white;

    &.react-select__control--is-focused {
      box-shadow: none;
      border: @border !important;
    }

    .react-select__value-container {
      padding: 12px 16px !important;
    }

    .react-select__input-container {
      display: none;
    }
  }
}

.Select.error .react-select__control {
  background-color: @error-100;
  border-color: @error-100;
}
