.btn {
  font-size: 11px;
  line-height: 18px;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: @letter-spacing-wider;
  padding: 11px 16px;
  border-radius: @border-radius;

  &.with-shadow {
    box-shadow: @box-shadow;
  }

  .svg-inline--fa {
    font-size: 16px;
    vertical-align: middle;
    position: relative;
    bottom: 1px;

    &.icon-addon-right {
      margin-right: 12px;
    }

    &.icon-addon-left {
      margin-left: 12px;
    }
  }

  &.btn-link .svg-inline--fa {
    &.icon-addon-right {
      margin-right: 10px;
    }

    &.icon-addon-left {
      margin-left: 10px;
    }
  }

  &.btn-sm {
    min-height: 32px;
    line-height: 18px;
    padding: 7px 12px;

    .svg-inline--fa {
      font-size: 16px;
    }
  }

  &.btn-big {
    padding: 15px 16px !important;
  }

  &.btn-default {
    color: @text-color;
    background: @white;
    border: @border;
    padding: 10px 16px;

    & > .svg-inline--fa:first-child,
    & > span > .svg-inline--fa:first-child {
      color: @neutral-400;
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      color: @text-color;
      background: @neutral-100;
      border-color: @neutral-300;
      box-shadow: none;

      & > .svg-inline--fa:first-child,
      & > span > .svg-inline--fa:first-child {
        color: @secondary-600;
      }
    }

    &.btn-sm {
      padding: 6px 12px;

      &.only-icon {
        width: 32px;
        padding: 6px !important;
      }
    }
  }

  &:not(.btn-default) {
    border: none;
  }

  &.btn-link {
    color: @text-color;
  }

  &.btn-link-inline {
    font-size: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    padding: 0;
    border: none;
    color: @link-color;
    display: inline;
    vertical-align: inherit;
    text-align: start;
    white-space: normal;
    text-transform: inherit;
    text-decoration: underline;
    text-underline-offset: 2px;

    &:is(:hover, :focus):not(.btn-disabled) {
      color: @link-hover-color;
      text-decoration: none;
    }

    &:disabled {
      opacity: 0.4;
    }

    &.btn-sm {
      font-size: 85%;
    }

    &.dark {
      color: @neutral-800;

      &.muted {
        color: @neutral-400;
      }

      &:hover,
      &:focus {
        color: @neutral-800;
      }
    }

    &.danger-muted {
      color: @neutral-400;

      &:hover,
      &:focus {
        color: @error-200;
      }
    }

    &.warning-muted {
      color: @neutral-400;

      &:hover,
      &:focus {
        color: @warning-500-base;
      }
    }

    &.partly-underlined {
      text-decoration: none;

      &:is(:hover, :focus) .underline {
        text-decoration: none !important;
      }
    }

    &.btn-collapse {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      text-align: center;

      .fa-angle-down {
        bottom: 0;
      }

      &:not(.btn-disabled):is(:hover, :focus) {
        color: @secondary-600;
        background-color: @neutral-100;
      }
    }
  }

  &.btn-link-muted {
    color: @neutral-400;

    &:hover,
    &:focus {
      color: @secondary-500-base;
    }
  }
}

.clickable:hover .btn-collapse {
  color: @secondary-600;
  background-color: @neutral-100;

  svg {
    color: @secondary-600;
  }
}

.btn:not(.btn-link) {
  &.disabled,
  &[disabled],
  &[disabled]:hover,
  &.btn-disabled,
  &.btn-disabled:hover {
    cursor: not-allowed;
    opacity: 0.65 !important;
    box-shadow: none;
    background: @neutral-100 !important;
    color: fade(@neutral-400, 70%) !important;

    &.btn-default {
      border-color: @border-color !important;

      & > .svg-inline--fa:first-child,
      & > span > .svg-inline--fa:first-child {
        color: @neutral-400 !important;
      }
    }
  }

  &.with-big-icon {
    padding: 10px 16px 8px 16px;
  }

  &.btn-default.no-label {
    padding: 10px 12px;
  }
}

.btn-icon {
  margin-right: 9px;
}

.block-button {
  padding: 8px;

  .btn {
    color: @neutral-400;
    background-color: @neutral-50;
    border: none;
    border-radius: @border-radius;
    font-size: 12px;
    line-height: 16px;
    padding: 12px 20px;
    text-align: left;
    text-transform: none;
    .fade-transition();

    &.btn-primary {
      &:hover,
      &:focus {
        background-color: @secondary-100;
        color: @secondary-500-base;
      }
    }

    &.btn-success {
      &:hover,
      &:focus {
        background-color: @primary-100;
        color: @primary-500-base;
      }
    }
  }

  &.add-button {
    padding: 12px 24px;

    .svg-inline--fa {
      font-size: 14px;
      margin-right: 10px;
    }

    .btn-primary {
      color: @secondary-500-base;
      background-color: @neutral-50;
      font-size: 11px;
      line-height: 16px;
      font-weight: 600;
      text-transform: uppercase;
      padding: 11px 26px;
    }
  }
}

.table + .block-button:last-child,
.table-responsive + .block-button:last-child {
  margin: 0 1em;

  .btn {
    font-size: 14px;
    font-weight: 500;
    padding: 13px 20px 13px 5px;
    text-decoration: underline;
    text-underline-offset: 2px;
    background-color: transparent;

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }

  &.with-actions {
    padding: 0px 8px 8px 8px;

    .btn {
      padding: 15px 20px 15px 30px;
    }
  }
}

.btn-toolbar {
  & > .btn-toolbar {
    display: inline-block;
    margin-left: 0;
  }

  & > .btn,
  & > .Select,
  & > .btn-group,
  & > .input-group,
  & > :where(span, div) > :where(.btn, .Select, .btn-group, .input-group) {
    margin-left: @padding-base*3;
  }
}

.dropdown.btn-group {
  display: inline-flex;
}

.top-buttons {
  display: flex;
  align-items: center;
  flex-shrink: 0;

  & > .btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
  }
}

.editor {
  position: relative;

  &:hover .full-screen-button .btn {
    opacity: 1;
  }

  .full-screen-button {
    position: absolute;
    z-index: 9;
    top: 18px;
    right: 24px;

    .btn {
      border: none;
      color: @neutral-400;
      opacity: 0;
      transition: opacity 200ms ease-in;

      &:hover,
      &:focus {
        color: @secondary-500-base;
      }
    }
  }
}

.btn-google {
  display: inline-flex;
  padding: 0;
  border: 0;

  &:hover,
  &:focus {
    box-shadow:
      0 1px 2px 0 rgba(60, 64, 67, 0.3),
      0px 1px 3px 1px rgba(60, 64, 67, 0.15);
  }
}

.btn-facebook {
  display: inline-flex;
  align-items: center;
  color: @white;
  background-color: @facebook-color;
  border-radius: 5px;
  letter-spacing: 1px;
  border: 0;
  text-transform: initial;
  padding: 9px 14px 9px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;

  &:hover,
  &:focus {
    color: @white;
    background-color: @facebook-color-secondary;

    .btn-facebook-icon {
      background: @facebook-color-secondary;
    }
  }

  &:disabled .btn-facebook-icon {
    background: @neutral-100;
  }

  .btn-facebook-icon {
    color: @white;
    background: @facebook-color;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.btn.color-primary {
  color: @secondary-500-base;

  &:hover,
  &:focus {
    color: @secondary-600;
  }
}

.btn.color-success {
  color: @primary-500-base;

  &:hover,
  &:focus {
    color: @primary-600;
  }
}

.btn.header-inline-button {
  font-size: 13px;
  text-transform: initial;
  letter-spacing: @letter-spacing-narrower;
}

.btn.btn-clear {
  .fa-xmark {
    color: @neutral-400;
    width: 17px;
    height: 17px;
  }

  &:hover .fa-xmark,
  &:focus .fa-xmark {
    color: inherit;
  }
}

.table-action-buttons {
  display: flex;
  align-items: center;
  margin-left: 18px;
  gap: 6px;

  .btn {
    display: inline-flex;
    padding: 1px 5px;
    box-sizing: content-box;

    .svg-inline--fa {
      font-size: 18px;

      &.img-rounded {
        min-width: 18px;
      }

      &.fa-arrow-right-from-bracket {
        font-size: 20px;
        bottom: 0;
      }

      &.fa-spinner {
        font-size: 14px;
        bottom: 0;
      }
    }
  }
}

.btn.circle-button {
  width: 28px;
  height: 28px;
  padding: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: @neutral-50;
  border-radius: @border-round;

  &.larger {
    width: 32px;
    height: 32px;
  }

  .svg-inline--fa {
    position: relative;
    font-size: 12px;
    bottom: 0;

    &.fa-xmark {
      font-size: 19px;
    }

    &.fa-warehouse {
      font-size: 10px;
    }
  }
}

.btn.table-collapse-button {
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: @border-round;
  overflow: hidden;
  color: @neutral-400;

  .svg-inline--fa {
    &.fa-angle-right {
      left: 1px;
    }

    &.fa-angle-down {
      bottom: 0px;
    }
  }
}

.btn.table-collapse-button:hover,
.table .folder-row:hover .btn.table-collapse-button {
  color: @secondary-600;
  background-color: @neutral-100;
}

.btn-block + .btn-block {
  margin-top: 10px;
}

.btn-toolbar-confirm {
  .btn-confirm {
    float: none;
  }
}

.btn-separator {
  display: inline-block;
  width: 1px;
  height: 24px;
  background: @neutral-200;
  margin: 0 16px;

  &:first-child,
  &:last-child {
    display: none;
  }
}

.save-buttons {
  display: flex;
  justify-content: flex-end;

  &:not(:empty) {
    margin-bottom: 1em;
  }
}

.btn-collapse-area {
  cursor: pointer;

  &:not(:has(.addons:hover)):hover .btn-collapse:not(.btn-disabled) {
    color: @secondary-600;
    background-color: @neutral-100;
  }
}

.feedback-buttons {
  display: inline-flex;
  gap: 8px;
  align-items: center;

  .btn {
    padding: 4px 8px;
    font-size: 14px;
    line-height: 24px;

    &.success:is(:hover, :focus, .active) {
      color: @primary-600 !important;
    }

    &.danger:is(:hover, :focus, .active) {
      color: @error-500-base !important;
    }
  }
}

.alert .feedback-buttons .btn svg {
  font-size: 20px;
}
