.btn.predefined-button,
.btn.predefined-search-link {
  display: inline-flex;
  align-items: center;
  color: @secondary-600;
  background-color: @transparent;
  border: 1px solid @secondary-500-base;
  font-weight: 600;
  margin-right: 10px;
  padding: 6px 8px;

  &:hover,
  &.active {
    background-color: @secondary-500-base;
    box-shadow: none;
    color: @white;
  }

  &.active {
    pointer-events: none;
  }
}

.predefined-search-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 4px 0;

  & > .predefined-search-link {
    margin: 4px !important;
  }
}

.group-separator {
  display: inline-block;
  width: 1px;
  height: 16px;
  background: @neutral-200;
  margin: 0 12px;

  &.compact {
    margin: 0 4px;
  }
}
