.description-box {
  &.box {
    padding: 24px;

    textarea {
      margin-top: 16px;
    }

    .markdown {
      margin-bottom: 0;
    }

    .animation-bar-box {
      margin-top: 16px;
    }
  }

  h3 {
    margin: 4px 0 6px 0;

    + .btn-toolbar {
      margin-top: -4px;

      .btn-link-inline {
        height: 32px;
        padding-right: 16px;
      }
    }
  }

  textarea {
    margin-bottom: 12px;
    max-height: calc(100vh - 340px);
  }

  .btn-toolbar {
    &.justify-center {
      margin-top: 16px;

      & > span {
        margin: 0 24px;
      }
    }

    &.block.same-width {
      margin-top: 24px;
    }
  }

  .btn-link-inline.underline {
    color: @neutral-800;

    &:not(:disabled):hover,
    &:not(:disabled):focus {
      color: @secondary-600;
    }
  }

  .markdown {
    margin: 8px 0;

    & > :not(.panel-preview),
    .panel-preview .panel-collapse {
      padding: 8px;
      margin: -8px;
      border-radius: @border-radius;

      &.clickable,
      &:has(.clickable) {
        cursor: pointer;

        &:hover {
          background: @neutral-100;
        }
      }
    }
  }

  .animation-bar-box {
    height: 105px;
    background: @neutral-50;
    justify-content: flex-start;
    padding: 12px 16px;
    margin-bottom: 12px;
    border: 1px solid @secondary-500-base;
    box-shadow: @outline-shadow;
    border-radius: 4px;
  }

  .panel-fade {
    &.collapsed::before {
      content: '';
      position: absolute;
      inset: 90px -8px 0;
      border-bottom-left-radius: @border-radius;
      border-bottom-right-radius: @border-radius;
      background: linear-gradient(to bottom, @transparent, @white);
      pointer-events: none;
    }
  }
}
