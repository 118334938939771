.circle-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: @border-round;

  &.inline {
    display: inline-flex;
  }

  &.bigger-icon {
    width: 48px;
    min-width: 48px;
    height: 48px;

    svg:not(.fa-plus) {
      font-size: 18px;
    }
  }

  &.smaller-icon {
    width: 24px;
    min-width: 24px;
    height: 24px;

    .svg-inline--fa {
      font-size: 11px;
      bottom: 0px;
    }
  }

  &.addon-right {
    margin-right: 20px;
  }

  & > svg.svg-inline--fa {
    width: 1.3em;
    height: 1.4em;
  }

  &.bold svg {
    color: @white;
  }

  &.blue {
    background-color: @secondary-100;
    color: @secondary-500-base;

    &.bold {
      background-color: @secondary-500-base;
    }
  }

  &.green {
    background-color: @primary-100;
    color: @primary-500-base;

    &.bold {
      background-color: @primary-500-base;
    }
  }

  &.light-green.bold {
    background-color: @primary-400;
  }

  &.red {
    background-color: @error-100;
    color: @brand-danger;

    &.bold {
      background-color: @brand-danger;
    }
  }

  &.violet {
    background-color: @accent-purple-100;
    color: @accent-purple-500-base;

    &.bold {
      background-color: @accent-purple-500-base;
    }
  }

  &.yellow {
    background-color: @warning-100;
    color: @warning-500-base;

    &.bold {
      background-color: @warning-500-base;
    }
  }

  &.gray {
    background-color: @neutral-400;
    color: @white;

    &.bold {
      background-color: @neutral-800;
    }
  }

  &.orange {
    background-color: @warning-200;
    color: @warning-500-base;

    &.bold {
      background-color: @warning-500-base;
    }
  }

  &.job-icon {
    width: 28px;
    min-width: 28px;
    height: 28px;

    svg.svg-inline--fa {
      width: 13px;
      height: 13px;
    }

    &.bigger {
      width: 36px;
      min-width: 36px;
      height: 36px;

      svg.svg-inline--fa {
        width: 17px;
        height: 17px;
      }
    }
  }
}

.modal-icon {
  position: absolute;
  z-index: 9999;
  left: 30px;
  top: -24px;
}

.box-icon {
  width: 48px;
  height: 48px;
  position: relative;
  top: 12px;
  left: 24px;
  margin: 0;
  z-index: 2;

  &.centered {
    top: 24px;
  }

  img,
  svg {
    max-width: 42px;
    max-height: 42px;
  }

  &.smaller {
    width: 40px;
    height: 40px;
    top: 20px;

    img,
    svg {
      max-width: 32px;
      max-height: 32px;
    }
  }

  svg.svg-inline--fa {
    width: 24px;
    height: auto;
  }
}

.transformation-backend-icon {
  position: relative;
  margin-right: 16px;

  .phase-icon {
    position: absolute;
    height: 20px;
    font-size: 12px;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: @white;
    background: @primary-500-base;
    border: 2px solid @white;
    border-radius: 10px;
    bottom: -8px;
    right: -8px;
    padding: 0 5px;
  }
}

.icon-with-icon {
  position: relative;
  display: inline-block;

  & > svg.svg-inline--fa:nth-child(2) {
    box-sizing: border-box;
    position: absolute;
    right: 4px;
    top: -6px;
    width: 24px;
    height: 24px;
    background-color: @white;
    border-radius: @border-round;
    border: 2px solid @white;

    &.bg-color-success {
      background-color: @primary-500-base;
    }

    &.bg-color-gray-dark {
      background-color: @neutral-400;
      color: @white;
    }

    &.fa-spin {
      padding: 2px;
    }

    &.fa-lock {
      padding: 2px 3px 3.5px;
    }

    &.align-bottom {
      top: auto;
      bottom: -6px;
    }

    &.small-icon {
      width: 18px;
      height: 18px;
    }

    &.status {
      width: 14px;
      height: 14px;
      right: -3px;
      top: auto;
      bottom: -2px;
    }
  }
}

.connector-icon {
  position: relative;
  top: -4px;
  margin-left: 5px;
  margin-right: 18px;
  flex-shrink: 0;
}

svg.dev-bucket > path {
  fill: @warning-500-base;
}

.component-icon-with-type {
  position: relative;

  img {
    display: inline-block;
    border-radius: @border-round;
  }

  .type-icon {
    position: absolute;
    right: -4px;
    bottom: -4px;
    display: inline-block;
    width: 22px;
    height: 22px;
    border: 2px solid @white;
    background: @white;
    border-radius: @border-round;
  }
}

.svg-inline--fa.fa-stack-2x.w-2em,
.fa-stack.w-2em {
  width: 2em;
}

.add-copy-workspace-icon {
  display: inline-block;
  position: relative;

  .fa-circle-plus,
  .fa-circle-arrow-right {
    position: absolute;
    right: -4px;
    bottom: -5px;
    font-size: 14px !important;
    background: @white;
    border: 1px solid @white;
    border-radius: @border-round;
  }

  .fa-circle-plus {
    color: @primary-500-base;
  }

  .fa-circle-arrow-right {
    color: @accent-purple-500-base;
  }
}

.icon-sets {
  & > img {
    background: @white;
    border: 2px solid @white;
    border-radius: 50px;
    overflow: hidden;

    & + img {
      margin-left: -10px;
    }
  }

  .f-16.font-medium {
    margin: 0px 2px;
  }

  .fa-square-chevron-right {
    position: relative;
    top: 1px;
    margin-left: 8px;
    margin-right: 8px;
  }
}

.type > svg.f-16 {
  vertical-align: -0.2em;
}
