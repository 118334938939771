@tailwind base;
@tailwind utilities;
@tailwind components;

@layer base {
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src:
      local(''),
      url('https://ui.keboola-assets.com/misc-ui-assets/v0.17.0-0-g78ee112/inter-v12-latin-ext_latin/1.0.0/inter-v12-latin-ext_latin-regular.woff2')
        format('woff2');
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src:
      local(''),
      url('https://ui.keboola-assets.com/misc-ui-assets/v0.17.0-0-g78ee112/inter-v12-latin-ext_latin/1.0.0/inter-v12-latin-ext_latin-500.woff2')
        format('woff2');
  }

  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src:
      local(''),
      url('https://ui.keboola-assets.com/misc-ui-assets/v0.17.0-0-g78ee112/inter-v12-latin-ext_latin/1.0.0/inter-v12-latin-ext_latin-600.woff2')
        format('woff2');
  }

  html {
    @apply tw-font-sans;
    font-size: 16px;
    line-height: 24px;
  }

  body {
    @apply tw-text-sm/[1.42857143];
  }

  h1 {
    @apply tw-text-3xl/[1.1];
  }

  h2 {
    @apply tw-text-lg/[1.1];
    @apply tw-text-neutral-900;
  }

  h3 {
    @apply tw-text-base/[1.1];
    @apply tw-text-neutral-900;
  }

  h4 {
    @apply tw-text-sm/[1.1];
    @apply tw-text-neutral-900;
    @apply tw-mt-6;
    @apply tw-mb-2;
  }

  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small {
    @apply tw-text-neutral-400;
    font-size: 75%;
  }

  b,
  strong {
    @apply tw-font-semibold;
  }
}
