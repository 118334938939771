.stream-mapping-template-code-button {
  &:hover {
    border-color: @neutral-200 !important;
    box-shadow: @box-shadow;

    &.text-muted:hover {
      color: @neutral-400 !important;
    }
  }
}
