.table:not(table) {
  display: table;

  > .thead,
  > .tbody,
  > .tfoot {
    > .tr {
      display: table-row;

      > .th,
      > .td {
        display: table-cell;
      }
    }

    // only fake table has rows as anchors
    > a.tr {
      color: inherit;
      text-decoration: none;

      &:hover,
      &:focus,
      &:active {
        color: @secondary-500-base;
      }
    }
  }

  > .thead {
    display: table-header-group;

    > .tr {
      > .th {
        &.contain-header {
          font-size: 16px;
          line-height: 18px;
          font-weight: 500;

          .component-type {
            position: relative;
            top: -1px;
            font-size: 11px;
            font-weight: 600;
            line-height: 14px;
            color: @neutral-400;
            text-transform: uppercase;
            margin-left: 5px;
          }
        }
      }
    }
  }

  > .tbody {
    display: table-row-group;
  }

  > .tfoot {
    display: table-footer-group;
  }

  .tr.divide-row {
    pointer-events: none;
    position: relative;
    height: 29px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      border-top: 1px dashed @neutral-100;
    }

    .divide-row-label {
      position: absolute;
      display: inline-block;
      left: 42px;
      font-size: 11px;
      line-height: 13px;
      font-weight: 500;
      background: @neutral-50;
      padding: 8px 11px;
      text-transform: uppercase;
      border-radius: 15px;

      .fa-clock {
        font-size: 13px;
        margin-right: 6px;
      }
    }
  }
}

// common table styles
.table {
  margin-bottom: 0;
  background: @transparent;
  border-collapse: separate;

  &.border-collapsed {
    border-collapse: collapse;
  }

  &.fixed {
    table-layout: fixed;
  }

  &.without-header {
    padding-top: 8px;
  }

  &.compact {
    > thead,
    > .thead,
    > tbody,
    > .tbody,
    > tfoot,
    > .tfoot {
      > tr,
      > .tr {
        > th,
        > .th,
        > td,
        > .td {
          padding: 8px;
        }
      }
    }
  }

  > thead,
  > .thead,
  > tbody,
  > .tbody,
  > tfoot,
  > .tfoot {
    > tr,
    > .tr {
      &.row-sort {
        & > td.row-sort-handle,
        & > .td.row-sort-handle {
          padding-left: @padding-base * 2;

          .fa-bars {
            color: @neutral-400;
            margin-right: 8px;
            opacity: 0;
            transition: opacity 200ms ease-in;
          }
        }

        &:hover > td:first-of-type .fa-bars,
        &:hover > .td:first-of-type .fa-bars {
          opacity: 1;

          &.not-allowed {
            opacity: 0.4;
          }
        }
      }

      & > td:last-of-type,
      & > .td:last-of-type {
        .btn-link:not(.btn-link-inline):not(.dropdown-toggle) {
          padding: 10px;
          min-height: 40px;
        }
      }

      .actions-container {
        display: flex;
        justify-content: flex-end;

        .actions,
        .not-actions {
          min-height: 42px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }

        &.with-inline-buttons :is(.actions, .not-actions) {
          min-height: auto;
        }

        > .text {
          padding: 12px 10px;
          line-height: 18px;
        }

        .row-dropdown {
          right: -8px;
        }

        .pull {
          position: relative;
          right: -8px;
        }
      }

      @media all and (min-width: @screen-md-min) {
        &.hoverable-actions-with-replacement {
          .actions-container {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;

            > .actions,
            > .not-actions {
              grid-area: 1 / 1 / 2 / 2;
              transition: opacity 200ms ease-in-out;
            }

            > .actions {
              opacity: 0;
            }

            > .not-actions {
              opacity: 1;
            }
          }

          .actions-container.force-actions,
          &:hover .actions-container,
          &:focus-within .actions-container {
            > .actions {
              opacity: 1;
            }

            > .not-actions {
              opacity: 0;
            }
          }
        }

        &.hoverable-actions {
          & > td:last-of-type:not(:first-of-type),
          & > .td:last-of-type:not(:first-of-type) {
            opacity: 0;
            transition: opacity 200ms ease-in-out;
          }

          &:hover > td:last-of-type,
          &:hover > .td:last-of-type,
          &:focus-within > td:last-of-type,
          &:focus-within > .td:last-of-type {
            opacity: 1;
          }
        }
      }

      &.with-action-buttons th,
      &.with-action-buttons .th {
        height: 66px;
        padding-top: 20px;
        padding-bottom: 18px;
      }

      > th,
      > .th,
      > td,
      > .td {
        line-height: 20px;
        padding: @table-cell-padding;
        border-top: none;
        float: none;

        &:not(.vertical-text-top) {
          vertical-align: middle;
        }

        &:first-of-type {
          padding-left: @padding-base * 6;

          &.with-row-sort-handle {
            padding-left: @padding-base * 7;
          }
        }

        &:last-of-type {
          padding-right: @padding-base * 6;
        }

        &:last-of-type:not(:first-of-type):not(.text-center):not(.text-left) {
          text-align: right;
        }
      }
    }
  }

  > thead > tr,
  > .thead > .tr {
    &:not(.no-shadow) {
      box-shadow: @table-box-shadow;
    }

    > th,
    > .th {
      border-bottom: none;
      font-weight: 500;
      padding-top: 24px;
      padding-bottom: 22px;

      .dropdown-toggle {
        padding: 5px 12px 3px 12px;
      }

      &.with-buttons {
        padding: 10px 23px 10px 12px;

        & > .btn-link {
          padding: 5px;
        }
      }
    }
  }

  > tbody > tr,
  > .tbody > .tr {
    > td.bg-danger,
    > td.bg-warning,
    > td.bg-success,
    > td.bg-info,
    > td.bg-selected,
    > .td.bg-danger,
    > .td.bg-warning,
    > .td.bg-success,
    > .td.bg-info,
    > .td.bg-selected {
      &.bg-selected {
        background-color: @neutral-100;
      }
    }
  }

  > tbody > tr > td:last-of-type .btn-link .svg-inline--fa,
  > .tbody > .tr > .td:last-of-type .btn-link .svg-inline--fa {
    font-size: 16px;
  }

  &.table-hover {
    &:not(.condensed) {
      border-spacing: 0 4px;
    }

    > tbody > tr:hover,
    > .tbody > .tr:hover {
      background-color: @table-bg-hover;

      &.no-hover {
        background-color: inherit;

        td,
        .td {
          background-image: none !important;
        }
      }
    }
  }

  &.condensed {
    > thead > tr > th,
    > .thead > .tr > .th {
      padding-top: 14px;
      padding-bottom: 14px;
    }

    > tbody > tr > td,
    > .tbody > .tr > .td {
      height: 48px;
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }

  .config-name,
  .component-name {
    font-size: 14px;
    line-height: 18px;
  }

  .list-row-inline-edit-input-wrapper {
    display: flex;
    &.editing {
      margin-bottom: -7px;

      .inline-edit-input {
        white-space: nowrap;

        & > div {
          margin-right: -15px;
          vertical-align: top;

          .form-control {
            height: auto;
            min-width: 120px;
            max-width: 150px;
            padding-bottom: 7px;

            @media all and (min-width: @screen-md-min) {
              max-width: 250px;
            }

            @media all and (min-width: @screen-lg-min) {
              max-width: 380px;
            }
          }
        }

        .btn {
          left: -6px;
          top: 2px;

          @media all and (min-width: @screen-sm-min) {
            top: -4px;
          }

          &[type='reset'] > svg {
            width: 16px;
            height: 16px;
          }
        }
      }
    }

    .btn-link-muted {
      margin-left: 6px;
      padding: 1px 5px 3px 3px;
      opacity: 0;
      .fade-transition(opacity);

      .svg-inline--fa {
        font-size: 12px;
      }
    }
  }

  .with-expand {
    position: relative;
    margin-left: 28px;

    .table-collapse-wrapper {
      position: absolute;
      left: -28px;
    }
  }

  .tr:hover .list-row-inline-edit-input-wrapper .btn-link-muted {
    opacity: 1;
  }

  #action-dropdown + .dropdown-menu-right {
    right: -14px;
  }

  &.table-bordered {
    thead > tr > th,
    tbody > tr > td {
      padding: 6px 10px;
    }

    thead > tr {
      box-shadow: none;

      & > th {
        border-bottom: @border;
      }
    }
  }

  .folder-row {
    .inline-edit-input .form-control {
      height: 15px;
    }
  }

  @media all and (min-width: @screen-md-min) {
    .folder-row .folder-name .btn-link-muted,
    .variable-row .variable-row-hover-action {
      opacity: 0;
      transition: opacity 200ms ease-in-out;
    }

    .folder-row:hover .folder-name .btn-link-muted,
    .variable-row:hover .variable-row-hover-action {
      opacity: 1;
    }
  }
}

@media screen and (max-width: @screen-sm-min) {
  .table-responsive {
    border: none;

    > .table > thead > tr > th,
    > .table > tbody > tr > td {
      white-space: initial;
    }
  }
}

.table-condensed {
  width: 100%;
  max-width: 100%;
}

// make compact tables even more compact in modal
.modal .table.compact {
  > thead,
  > .thead,
  > tbody,
  > .tbody,
  > tfoot,
  > .tfoot {
    > tr,
    > .tr {
      > th,
      > .th,
      > td,
      > .td {
        padding: 8px;
      }

      > th:first-of-type,
      > .th:first-of-type,
      > td:first-of-type,
      > .td:first-of-type {
        padding-left: 0px;
      }

      > th:last-of-type,
      > .th:last-of-type,
      > td:last-of-type,
      > .td:last-of-type {
        padding-right: 0px;
      }
    }
  }
}

// table in box, immediately after box-header
.box .box-header.above-table + .table,
.box .box-header.above-table + .table-responsive {
  th,
  .th {
    padding-top: 18px;
    padding-bottom: 20px;
  }
}

.table-hover thead + tbody:before,
.table-hover .thead + .tbody:before {
  content: '';
  display: block;
}

.box .table-hover:not(.condensed) {
  padding-bottom: 4px;
}

.block-button + .table-hover,
.box .table-hover + .block-button,
.box .table-responsive + .block-button {
  margin-top: -4px;
}

.well.with-table {
  padding: 5px 5px 12px 5px;
  border-color: @neutral-400;

  & > .table {
    & > thead tr {
      box-shadow: none;

      th {
        color: @neutral-400;
        font-size: 12px;
        line-height: 12px;
        font-weight: 500;
        padding: 12px 15px 5px 15px;
      }
    }

    & > tbody tr td {
      padding: 9px 15px 0px 15px;

      .btn-link[type='button']:not(.btn-link-inline) {
        padding: 4px 15px;
      }
    }
  }
}

table.events-table > tbody > tr > td {
  &.text-danger:first-child {
    font-weight: 500;

    span.circle-icon {
      width: 28px;
      min-width: 28px;
      height: 28px;
    }
  }

  &:last-child:not(:first-child) {
    font-weight: 500;
  }

  span.event-message {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.box {
  .component-name-above-table {
    align-items: stretch;

    & > *:first-child {
      padding: 16px 0px 0px 24px;
    }

    .btn-collapse-trigger {
      position: relative;
      top: 24px;
      right: 20px;
    }

    a,
    .name {
      font-size: 16px;
      font-weight: 500;
      text-transform: initial;
      letter-spacing: @letter-spacing-narrower;
      color: @neutral-800;

      + .type {
        margin-top: 2px;
      }
    }
  }

  .collapse-above-table {
    padding: 16px 20px 8px 24px;

    & + .table > thead > tr > th {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }

  .btn-collapse-area:has(+ .collapsed-configurations:hover) .btn-collapse {
    color: @secondary-600;
    background: @neutral-100;
  }

  .collapsed-configurations {
    padding: 16px 24px;
  }
}

.table.in-modal {
  border: @border;
  border-radius: @border-radius;
  border-spacing: initial;
  margin-bottom: @box-margin;
  overflow: hidden;

  .thead > .tr {
    box-shadow: none;

    & > .th {
      padding: 10px 16px 9px 16px;
      border-bottom: @border;
    }
  }

  .tbody > .tr > {
    & > .td {
      padding: 14px 16px 13px 16px;
      line-height: 24px;

      &:last-child .btn {
        position: relative;
        padding: 5px;
        right: -5px;
      }
    }

    &.clickable:hover > .td,
    &.clickable:focus > .td {
      color: @secondary-500-base;
    }
  }
}

.table.stretch-modal {
  margin-left: -32px;
  width: calc(100% + 64px);
  max-width: calc(100% + 64px);

  & > thead > tr > th {
    padding-top: 14px;
    padding-bottom: 14px;
  }

  & > thead > tr > th,
  & > tbody > tr > td {
    &:first-child {
      padding-left: 32px;
    }

    &:last-child {
      padding-right: 32px;
    }
  }
}

.dragable-columns th {
  position: relative;
  padding-right: 32px !important;

  .fa-grip-dots-vertical {
    display: none;
    color: @neutral-400;
    position: absolute;
    right: 12px;
    top: calc(50% - 8px);
  }

  &:hover .fa-grip-dots-vertical {
    display: inline-block;
  }
}

&.full-screen .table-responsive.sticky-header {
  max-height: calc(100vh - 220px);
}

.table-responsive.sticky-header {
  overflow-y: auto;
  max-height: calc(100vh - 510px);

  @media all and (max-height: 1000px) {
    max-height: calc(100vh - 425px);
  }

  th,
  .filters-row td {
    position: sticky;
    z-index: 10;
  }

  th {
    height: 70px;
    top: 0;
  }

  .filters-row td {
    height: 50px;
    top: 70px;
  }
}

@media all and (max-height: 1000px) {
  .main-container:has(#data-sample-table .table-responsive.sticky-header) {
    padding-bottom: 2em;
  }
}
