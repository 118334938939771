.project-settings-page {
  // left side
  .row {
    line-height: 60px;
    white-space: nowrap;
    vertical-align: middle;

    &.odd {
      background-color: @neutral-50;
    }
  }
}
