.litepicker {
  --litepicker-month-header-color: @neutral-800;
  --litepicker-button-prev-month-color-hover: @secondary-500-base;
  --litepicker-button-next-month-color-hover: @secondary-500-base;
  --litepicker-day-color: @neutral-800;
  --litepicker-day-color-hover: @secondary-500-base;
  --litepicker-is-today-color: @neutral-800;
  --litepicker-is-in-range-color: @secondary-100;
  --litepicker-is-start-color-bg: @secondary-500-base;
  --litepicker-is-end-color-bg: @secondary-500-base;
  --litepicker-button-apply-color-bg: @secondary-500-base;
  --litepicker-button-reset-color-hover: @secondary-500-base;
  --litepicker-highlighted-day-color: @neutral-800;
  --litepicker-container-months-box-shadow-color: @transparent;
  --litepicker-footer-box-shadow-color: @transparent;
  --litepicker-day-height: 25px;
  --litepicker-day-width: 30px;
  --litepicker-month-width: calc(var(--litepicker-day-width) * 7);

  top: 44px !important;
  left: 0 !important;
  border-radius: 6px;
  transition: none;
  box-shadow: 0px 10px 20px rgba(100, 135, 153, 0.18);

  .preview-date-range {
    display: none;
  }

  .container__months {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    .month-item-header {
      font-size: 14px;
    }

    .day-item {
      font-size: 13px;
      transition: none;

      &.is-today {
        font-weight: 600;
      }

      &.is-locked {
        background: @neutral-50;
        border-radius: 0px;
      }
    }
  }

  .container__footer {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;

    .time-plugin-container {
      display: flex;
      flex-grow: 1;
      padding: 10px;
      background-color: var(--inrange-color);

      .time-plugin-custom-block {
        &:nth-of-type(2) {
          margin-left: 30%;
        }

        select {
          padding: 2px;
        }
      }
    }

    button.button-cancel {
      display: none;
    }

    button.button-apply {
      padding: 8px 16px;
      font-weight: 600;
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 1px;
      text-transform: uppercase;
      border-radius: @border-radius;
      border: none;

      &:hover,
      &:focus {
        background-color: darken(@secondary-500-base, 10%);
      }
    }
  }
}
