.bigger-label {
  display: inline-block;
  background: @neutral-400;
  color: @white;
  padding: 5px 8px;
  font-size: 11px;
  line-height: 14px;
  font-weight: 600;
  margin-right: 10px;
  letter-spacing: @letter-spacing-wider;
  border-radius: @border-radius;

  &.clickable {
    .fade-transition(all, 120ms);

    &:hover,
    &:focus {
      color: @white;
      background: @secondary-500-base;
      text-decoration: none;
    }
  }
}

.react-select__option.options-group-label {
  position: relative;
  display: inline-flex;

  &::before {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    left: 0;
    right: 0;
    border: 1px dashed @neutral-50;
  }

  & > span {
    z-index: 1;
  }
}

.backend-size-label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: capitalize;

  &.pull-right {
    justify-content: flex-end;
  }

  &.emphasized {
    color: @secondary-500-base;
    font-size: 12px;
    text-transform: uppercase;

    span {
      margin-left: auto;
    }
  }

  svg:not(:last-of-type) {
    margin-right: 5px;
  }

  &:not(.pull-right) > svg:last-of-type {
    margin-right: 10px;
  }
}

td,
.td {
  & > .backend-size-label.emphasized {
    font-size: 16px;
  }

  &.pl-0 > .backend-size-label {
    margin-left: 16px;
  }

  .backend-size-label {
    padding-bottom: 4px;
  }
}

.default-backend-size-placeholder {
  text-transform: none;
  font-size: 13px;
  letter-spacing: initial;
  text-decoration: none;

  .svg-inline--fa {
    margin-left: 10px;
    bottom: 0;
  }
}
