.animation-bar-core {
  background: linear-gradient(to right, @neutral-150 46%, @neutral-100 50%, @neutral-150 54%);
  animation: gradient 1600ms ease-in-out infinite;
  background-size: 200%;
}

@keyframes gradient {
  0% {
    background-position: 110% 50%;
  }
  100% {
    background-position: -10% 50%;
  }
}
