.gooddata-dashboard {
  --gd-dashboards-section-title-color: @neutral-800;
  --gd-dashboards-section-description-color: @neutral-400;

  .gd-dashboards-root {
    font-family: @font-family-sans-serif;
  }

  .gd-typography--h2 {
    font-weight: 500;
  }

  .dash-section {
    margin-bottom: 0;
  }

  .gd-fluidlayout-row {
    margin-bottom: @box-margin;
  }

  .dash-header.s-top-bar,
  .gd-row-header-title-wrapper::after {
    display: none;
  }

  .item-headline {
    font-size: 16px;
  }

  .headline .headline-primary-item {
    height: 32px;
    line-height: 32px;

    .headline-value {
      font-size: 32px;
    }
  }

  .gd-date-filter-button {
    font-size: 14px;
    height: 50px;
    line-height: 20px;
  }

  .gd-dash-header-wrapper {
    z-index: 101 !important;
  }
}

.gd-overlay-content {
  --gd-palette-primary-base: @secondary-500-base;

  .dropdown-body {
    min-width: 300px;
  }

  .gd-extended-date-filter-body .gd-filter-list-item {
    font-family: @font-family-sans-serif;
    font-size: 14px;
    line-height: 20px;
  }
}
